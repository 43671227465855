<template>
  <landing-header />
  <main>
    <section class="hero">
      <h1>Leverage your business through Mekari Developer API</h1>
      <p class="subtitle">
        Build your application on top of Mekari Platform and connect your
        business process through powerful APIs from all Mekari products.
      </p>
      <p>
        <a href="/docs/kb">
          <mpx-button>Get Started</mpx-button>
        </a>
      </p>
      <div class="image">
        <hero />
      </div>
    </section>

    <section class="explore">
      <h2>Explore the docs</h2>
      <p class="subtitle">
        Find the tools, frameworks, and resources you need to build apps.
      </p>
      <div class="products">
        <a href="#" class="product-box">
          <div class="logo">
            <jurnal-logo />
          </div>
          <h3>Jurnal</h3>
          <p class="description">
            Online accounting software for finance and bookkeeping automation
          </p>
          <p class="link coming-soon">Coming soon</p>
        </a>

        <a href="/docs/kb" class="product-box">
          <div class="logo">
            <talenta-logo />
          </div>
          <h3>Talenta</h3>
          <p class="description">
            Cloud-based software for end-to-end HR operations, payroll,
            attendance, and benefit
          </p>
          <p class="link">
            Read the guide
            <mpx-icon :variant="'arrow-right'" :primary-color="'primary-400'" />
          </p>
        </a>

        <a href="/docs/kb" class="product-box">
          <div class="logo">
            <klikpajak-logo />
          </div>
          <h3>KlikPajak</h3>
          <p class="description">
            DJP-certified application service provider for tax management and
            compliance
          </p>
          <p class="link">
            Read the guide
            <mpx-icon :variant="'arrow-right'" :primary-color="'primary-400'" />
          </p>
        </a>
      </div>
    </section>

    <section class="ready">
      <h2>Ready to build?</h2>
      <p class="subtitle">See the documentation to get started.</p>
      <p>
        <a href="/docs/kb">
          <mpx-button>Explore the docs</mpx-button>
        </a>
      </p>
    </section>
  </main>
  <landing-footer />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MpxButton from "@mekari/pixel/src/js/components/Button/index.vue";
import MpxIcon from "@mekari/pixel/src/js/components/Icon/index.vue";
import Hero from "@/assets/images/home/hero.svg";
import JurnalLogo from "@/assets/images/home/jurnal.svg";
import TalentaLogo from "@/assets/images/home/talenta.svg";
import KlikpajakLogo from "@/assets/images/home/klikpajak.svg";
import LandingHeader from "@/components/layouts/LandingHeaderComponent.vue";
import LandingFooter from "@/components/layouts/LandingFooterComponent.vue";

export default defineComponent({
  components: {
    MpxButton,
    MpxIcon,
    Hero,
    JurnalLogo,
    TalentaLogo,
    KlikpajakLogo,
    LandingHeader,
    LandingFooter,
  },
});
</script>

<style lang="scss" scoped>
.hero {
  width: 100%;
  padding: 48px;
  text-align: center;
  background: #f9fbfd;

  h1 {
    width: 100%;
    max-width: 631px;
    margin: auto;
    font-weight: bold;
    font-size: 40px;
    line-height: 140%;
  }

  .subtitle {
    width: 100%;
    max-width: 392px;
    margin: 8px auto 24px;
    color: #626b79;
    font-size: 14px;
    line-height: 24px;
  }

  .image {
    width: 100%;
    max-width: 405px;
    height: 292px;
    margin: 24px auto 0px;
  }
}

.explore {
  background: #f3f8fa;
  padding: 88px 0px;

  h2 {
    font-size: 24px;
    color: #232933;
    text-align: center;
    margin-bottom: 4px;
  }

  .subtitle {
    text-align: center;
    color: #626b79;
  }

  .products {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .product-box {
      display: block;
      background: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 6px;
      padding: 24px;
      margin: 32px 24px 0px;
      width: 288px;
      height: 268px;
      text-decoration: none;
      transition: all 300ms;

      &:hover {
        border: 1px solid #040484;
        cursor: pointer;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
          0px 4px 6px -2px rgba(0, 0, 0, 0.05);
      }

      .logo {
        width: 56px;
        height: 56px;
        margin-bottom: 24px;
      }

      h3 {
        margin-bottom: 8px;
        font-size: 20px;
        line-height: 32px;
        color: #232933;
      }

      .description {
        color: #626b79;
        margin-bottom: 20px;
      }

      .link {
        color: #4b61dd;

        &.coming-soon {
          color: #8b95a5;
        }
      }
    }
  }
}

.ready {
  background: #172b4d;
  padding: 64px 0px;
  color: #fff;
  text-align: center;

  h2 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 4px;
  }

  .subtitle {
    margin-bottom: 24px;
  }
}

/** 767px - 640px */
@media only screen and (max-width: 767px) {
  .hero h1 {
    font-size: 34px;
  }
}

/** 639px - 480px */
@media only screen and (max-width: 639px) {
  .hero {
    h1 {
      font-size: 26px;
    }

    .image .image svg {
      height: 276px;
    }
  }

  .explore {
    padding: 88px 20px;
  }
}

/** 479px - 360px */
@media only screen and (max-width: 479px) {
  .hero {
    h1 {
      font-size: 24px;
    }

    .image,
    .image svg {
      height: 177px;
    }
  }
}
</style>
