<template>
  <header :class="['header', headerClass]">
    <div class="wrapper">
      <div class="logo">
        <logo />
      </div>
      <div class="menu-center">
        <a
          href="/docs/kb"
          @mouseover="openMenuContainer('api')"
          @mouseleave="closeMenuContainer('menu-center')"
        >
          APIs <mpx-icon :variant="'chevron-down'" />
        </a>
        <a href="/docs/kb">Documentation</a>

        <!-- Enable this when navigation content for documentation is ready
        <a href="/docs/kb" @mouseover="openMenuContainer('documentation')" @mouseleave="closeMenuContainer('menu-center')">
          Documentation <mpx-icon :variant="'chevron-down'" />
        </a> -->

        <!-- Enable this after support ticket feature is completed
        <a href="#">Support </a>
        -->
      </div>
      <div class="menu-right">
        <a href="/users/sign_in" class="sign-up">{{
          $t("layout.landing.sign_in")
        }}</a>
      </div>
      <div class="menu-container">
        <span :class="['arrow-border', `arrow-on-${activeMenuBox}`]">
          <span class="arrow"></span>
        </span>
        <div
          :class="['box', activeMenuBox]"
          @mouseover="keepMenuContainer"
          @mouseleave="closeMenuContainer('menu-box')"
        >
          <template v-if="activeMenuBox === 'api'">
            <div class="product">
              <div class="product-logo">
                <jurnal-logo />
              </div>
              <div class="product-description">
                <h3>Jurnal <span>(Coming Soon)</span></h3>
                <p>
                  Online accounting software for finance and bookkeeping
                  automation
                </p>
              </div>
            </div>

            <a href="/docs/kb/product-api" class="product">
              <div class="product-logo">
                <talenta-logo />
              </div>
              <div class="product-description">
                <h3>Talenta</h3>
                <p>
                  Cloud-based software for end-to-end HR operations, payroll,
                  attendance, and benefit
                </p>
              </div>
            </a>

            <a href="/docs/kb/product-api" class="product">
              <div class="product-logo">
                <klikpajak-logo />
              </div>
              <div class="product-description">
                <h3>Klikpajak</h3>
                <p>
                  DJP-certified application service provider for tax management
                  and compliance
                </p>
              </div>
            </a>
          </template>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MpxIcon from "@mekari/pixel/src/js/components/Icon/index.vue";
import Logo from "@/assets/images/logo.svg";
import JurnalLogo from "@/assets/images/home/jurnal.svg";
import TalentaLogo from "@/assets/images/home/talenta.svg";
import KlikpajakLogo from "@/assets/images/home/klikpajak.svg";

interface IData {
  activeMenuBox: string;
  headerClass: string;
  mouseOnMenuBox: boolean;
  mouseOnMenuCenter: boolean;
}

export default defineComponent({
  components: {
    MpxIcon,
    Logo,
    JurnalLogo,
    TalentaLogo,
    KlikpajakLogo,
  },
  data(): IData {
    return {
      activeMenuBox: "",
      headerClass: "",
      mouseOnMenuBox: false,
      mouseOnMenuCenter: false,
    } as IData;
  },
  methods: {
    openMenuContainer(type = ""): void {
      this.activeMenuBox = type;
      this.headerClass = "header-menu-container-open";
      this.mouseOnMenuCenter = true;
    },
    keepMenuContainer(): void {
      this.mouseOnMenuBox = true;
    },
    closeMenuContainer(type: string): void {
      if (type === "menu-box") {
        this.mouseOnMenuBox = false;
      }

      if (type === "menu-center") {
        this.mouseOnMenuCenter = false;
      }

      // Delay the close execution to make sure that the value
      // is set before deciding if the menu container should be closed or not
      // Because there is a gap time when mouse moving from menu center
      // to menu box
      setTimeout(() => {
        if (this.mouseOnMenuBox || this.mouseOnMenuCenter) {
          return;
        }

        this.headerClass = "";
      }, 100);
    },
  },
});
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 48px;
  background: #fff;

  &.header-menu-container-open .wrapper {
    .menu-container {
      opacity: 1;
      height: calc(100vh - 48px);
    }
  }

  .wrapper {
    width: 100%;
    max-width: 1240px;
    margin: 0px auto;
    padding: 0px 8px;
    display: flex;

    .logo {
      float: left;
      height: 32px;
      width: 96px;
      margin: 8px 0px;
    }

    .menu-center {
      width: 254px;
      margin: 0px auto;

      a {
        position: relative;
        color: #232933;
        text-decoration: none;
        display: inline-block;
        padding: 14px 20px;
        transition: color 300ms;

        &:hover {
          color: #4b61dd;

          i {
            --ic-primary-color: #4b61dd;
          }
        }
      }

      i {
        width: 16px;
        height: 16px;
        display: inline-block;
      }
    }

    .menu-right {
      .sign-up {
        position: relative;
        color: #626b79;
        text-decoration: none;
        display: inline-block;
        padding: 14px 20px;
      }
    }

    .menu-container {
      position: absolute;
      top: 48px;
      left: 0;
      width: 100%;
      height: 0px;
      z-index: 1;
      overflow: hidden;
      opacity: 0;
      transition: height 300ms, opacity 300ms;

      .arrow-border,
      .arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 12px solid #d0d6dd;
      }

      .arrow-border {
        top: -1px;
        left: 50%;
        margin-left: 0;
        z-index: 1;
        transition: transform 300ms;

        &.arrow-on-api {
          transform: translateX(-55px);
        }

        &.arrow-on-documentation {
          transform: translateX(30px);
        }

        .arrow {
          border-bottom-color: #fff;
          top: 2px;
          left: -8px;
        }
      }

      .box {
        position: absolute;
        top: 11px;
        background: #fff;
        left: 50%;
        border: 1px solid #d0d6dd;
        box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
          0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);

        &.api {
          width: 712px;
          margin-left: -356px;
          display: flex;
          flex-flow: wrap;
          padding: 16px;

          .product {
            display: block;
            width: 288px;
            height: 96px;
            margin: 16px;
            text-decoration: none;
            color: #626b79;

            .product-logo {
              width: 56px;
              height: 56px;
              margin-right: 25px;
              float: left;
            }

            .product-description {
              padding-left: 81px;

              h3 {
                color: #232933;
                font-size: 20px;
                line-height: 32px;
                font-weight: bold;

                span {
                  font-size: 14px;
                  color: #626b79;
                  font-weight: normal;
                }
              }

              p {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}

/** 1279px - 1024px */
@media only screen and (max-width: 1279px) {
  .header .wrapper {
    padding: 0px 50px;
  }
}

/** 1023px - 960px */
@media only screen and (max-width: 1023px) {
  .header .wrapper {
    padding: 0px 40px;
  }
}

/** 959px - 768px */
@media only screen and (max-width: 959px) {
  .header .wrapper {
    padding: 0px 30px;
  }
}

/** 767px - 640px */
@media only screen and (max-width: 767px) {
  .header .wrapper {
    padding: 0px 20px;

    .menu-container .box.api {
      width: 640px;
      margin-left: -320px;

      .product {
        width: 271px;
        height: 100px;

        .product-description h3 {
          font-size: 18px;
        }
      }
    }
  }
}

/** > 640px */
@media only screen and (max-width: 639px) {
  .header .wrapper .menu-center {
    display: none;
  }
}
</style>
