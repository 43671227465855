
import { defineComponent } from "vue";
import MpxIcon from "@mekari/pixel/src/js/components/Icon/index.vue";
import Logo from "@/assets/images/logo.svg";
import JurnalLogo from "@/assets/images/home/jurnal.svg";
import TalentaLogo from "@/assets/images/home/talenta.svg";
import KlikpajakLogo from "@/assets/images/home/klikpajak.svg";

interface IData {
  activeMenuBox: string;
  headerClass: string;
  mouseOnMenuBox: boolean;
  mouseOnMenuCenter: boolean;
}

export default defineComponent({
  components: {
    MpxIcon,
    Logo,
    JurnalLogo,
    TalentaLogo,
    KlikpajakLogo,
  },
  data(): IData {
    return {
      activeMenuBox: "",
      headerClass: "",
      mouseOnMenuBox: false,
      mouseOnMenuCenter: false,
    } as IData;
  },
  methods: {
    openMenuContainer(type = ""): void {
      this.activeMenuBox = type;
      this.headerClass = "header-menu-container-open";
      this.mouseOnMenuCenter = true;
    },
    keepMenuContainer(): void {
      this.mouseOnMenuBox = true;
    },
    closeMenuContainer(type: string): void {
      if (type === "menu-box") {
        this.mouseOnMenuBox = false;
      }

      if (type === "menu-center") {
        this.mouseOnMenuCenter = false;
      }

      // Delay the close execution to make sure that the value
      // is set before deciding if the menu container should be closed or not
      // Because there is a gap time when mouse moving from menu center
      // to menu box
      setTimeout(() => {
        if (this.mouseOnMenuBox || this.mouseOnMenuCenter) {
          return;
        }

        this.headerClass = "";
      }, 100);
    },
  },
});
