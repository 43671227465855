
import { defineComponent } from "vue";
import MpxButton from "@mekari/pixel/src/js/components/Button/index.vue";
import MpxIcon from "@mekari/pixel/src/js/components/Icon/index.vue";
import Hero from "@/assets/images/home/hero.svg";
import JurnalLogo from "@/assets/images/home/jurnal.svg";
import TalentaLogo from "@/assets/images/home/talenta.svg";
import KlikpajakLogo from "@/assets/images/home/klikpajak.svg";
import LandingHeader from "@/components/layouts/LandingHeaderComponent.vue";
import LandingFooter from "@/components/layouts/LandingFooterComponent.vue";

export default defineComponent({
  components: {
    MpxButton,
    MpxIcon,
    Hero,
    JurnalLogo,
    TalentaLogo,
    KlikpajakLogo,
    LandingHeader,
    LandingFooter,
  },
});
