<template>
  <footer class="footer">
    <div class="wrapper">
      <div class="links">
        <a href="https://mekari.com/kebijakan-privasi/">Privacy</a>
        <a href="https://mekari.com/persyaratan-layanan/">Terms of use</a>
        <span>© 2021 PT Mid Solusi Nusantara</span>
      </div>
      <div class="logo">
        <logo />
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Logo from "@/assets/images/logo.svg";

export default defineComponent({
  components: {
    Logo,
  },
});
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 96px;
  background: #fff;

  .wrapper {
    width: 100%;
    max-width: 1272px;
    margin: 0px auto;
    padding: 24px;

    .logo {
      width: 144px;
      height: 48px;

      svg {
        width: 144px;
        height: 48px;
      }
    }

    .links {
      float: right;
      text-align: right;
      margin: 14px 0px;
      color: #626b79;

      a {
        text-decoration: none;
        display: inline-block;
        margin: 0px 20px;
      }
    }
  }
}

/** 767px - 640px */
@media only screen and (max-width: 767px) {
  .footer .wrapper .links a {
    margin: 0px 10px;
  }
}

/** 639px - 480px */
@media only screen and (max-width: 639px) {
  .footer {
    height: inherit;

    .wrapper {
      position: relative;

      .links {
        margin-top: 64px;
        float: none;
        width: 403px;
        margin-left: auto;
        margin-right: auto;
      }

      .logo {
        position: absolute;
        left: 50%;
        margin-left: -72px;
        top: 24px;
      }
    }
  }
}

/** 479px - 360px */
@media only screen and (max-width: 479px) {
  .footer .wrapper .links {
    width: 100%;
    text-align: center;

    a {
      display: block;
      margin: 5px 0px;
    }
  }
}
</style>
